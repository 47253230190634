<template>
  <div class="my-orders__table-wrapper">
    <div v-if="isOrdersLoaded" class="table">
      <div class="tr th tr--top">
        <div class="td text-left">
        <span @click="orderOrdersById" class="cursor-pointer">
          <span :class="{ 'text-primary': idOrder.isActive }">#ID</span>
        </span>
        </div>
        <div class="td">
        <span @click="orderOrdersByDate" class="cursor-pointer">
          <span :class="{ 'text-primary': dateOrder.isActive }">Datum</span>
        </span>
        </div>
        <div class="td" style="min-width: 106px;">
          Broj artikla
        </div>
        <div class="td">
          Cijena
        </div>
        <div class="td">
        <span @click="orderOrdersByStatus" class="cursor-pointer">
          <span :class="{ 'text-primary': statusOrder.isActive }">Status</span>
        </span>
        </div>
        <div class="td text-right">
          Opcije
        </div>
      </div>
      <div class="tr" v-for="(order, key) in orders" :key="key">
        <div class="td">
          <span><strong>#{{ order.id }}</strong></span>
        </div>
        <div class="td" style="justify-content: center;">
          <span>{{ order.createdAt | formatDate }}</span>
        </div>
        <div class="td" style="justify-content: center;">
          <span>
            <span>{{ order.items.length }} kom</span>
          </span>
        </div>
        <div class="td" style="justify-content: center;">
          <div style="display: flex; flex-direction: column">
            <span>{{ order.total|currencyFormat }} kn</span>
            <span>{{ order.total|euroPrice }} €</span>
          </div>
        </div>
        <div class="td" style="justify-content: center;">
          <span v-if="order.status === 'cart'" class="mc-pill mc-pill--invoice">ponuda</span>
          <span v-else class="mc-pill mc-pill--order">naručeno</span>
        </div>
        <div class="td" style="justify-content: flex-end;">
          <span v-if="order.status === 'cart'" class="mc-icon">
            <a :href="`/moje-narudzbe/${order.id}`" target="_blank" title="Pogledaj">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"/></svg>
            </a>
          </span>
          <span class="mc-icon">
            <a :href="`api/v1/exports/pdf/cart-export/${order.id}/${userId}/-1/0`" target="_blank" title="S popustom">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.363 2c4.155 0 2.637 6 2.637 6s6-1.65 6 2.457v11.543h-16v-20h7.363zm.826-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784zm-4.9 0h-1.599v3.686h1.599c.537 0 .961-.181 1.262-.535.555-.658.587-2.034-.062-2.692-.298-.3-.712-.459-1.2-.459zm-.692.783h.496c.473 0 .802.173.915.644.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12zm-2.74-.783h-1.668v3.686h.907v-1.277h.761c.619 0 1.064-.277 1.224-.763.095-.291.095-.597 0-.885-.16-.484-.606-.761-1.224-.761zm-.761.732h.546c.235 0 .467.028.576.228.067.123.067.366 0 .489-.109.199-.341.227-.576.227h-.546v-.944z"/></svg>
            </a>
          </span>
          <span class="mc-icon">
            <a :href="`api/v1/exports/pdf/cart-export/${order.id}/${userId}/0/-1`" target="_blank" title="Bez popusta">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.819 14.427c.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12h.496c.474 0 .803.173.916.644zm3.091-8.65c2.047-.479 4.805.279 6.09 1.179-1.494-1.997-5.23-5.708-7.432-6.882 1.157 1.168 1.563 4.235 1.342 5.703zm-7.457 7.955h-.546v.943h.546c.235 0 .467-.027.576-.227.067-.123.067-.366 0-.489-.109-.198-.341-.227-.576-.227zm13.547-2.732v13h-20v-24h8.409c4.858 0 3.334 8 3.334 8 3.011-.745 8.257-.42 8.257 3zm-12.108 2.761c-.16-.484-.606-.761-1.224-.761h-1.668v3.686h.907v-1.277h.761c.619 0 1.064-.277 1.224-.763.094-.292.094-.597 0-.885zm3.407-.303c-.297-.299-.711-.458-1.199-.458h-1.599v3.686h1.599c.537 0 .961-.181 1.262-.535.554-.659.586-2.035-.063-2.693zm3.701-.458h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784z"/></svg>
            </a>
          </span>
        </div>
      </div>
    </div>
    <div>
      <OrderModal :is-show="isModalShow" :order-id="tempModalOrderId" :items="tempModalOrderItems"></OrderModal>
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
import format from "date-fns/format";
import OrderModal from "./OrderModal";
import ClickOutside from "vue-click-outside";

export default {
  name: 'MyOrdersTable',
  filters: {
    formatDate: (value) => {
      if (value) return format(new Date(value), 'dd.MM.yyyy.')
    }
  },
  props: ['userId', 'company'],
  components: {
    OrderModal
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      orders: [],
      isOrdersLoaded: false,
      statusOrder: {
        query: 'desc',
        isActive: false
      },
      dateOrder: {
        query: 'desc',
        isActive: false
      },
      idOrder: {
        query: 'desc',
        isActive: false
      },
      isModalShow: false,
      tempModalOrderId: null,
      tempModalOrderItems: null,
    }
  },
  mounted() {
    ApiService.get('/api/v1/orders', `?company=${this.company}&order[id]=desc`).then(({data}) => {
      this.orders = [...data['hydra:member']]
      this.isOrdersLoaded = true
    })
  },
  methods: {
    orderOrdersById() {
      this.idOrder.isActive = true
      if (this.idOrder.query === 'asc') {
        this.idOrder.query = 'desc'
      } else {
        this.idOrder.query = 'asc'
      }

      this.isOrdersLoaded = false
      ApiService.get(
          '/api/v1/orders',
          `?company=${this.company}&order[id]=${this.idOrder.query}`
      ).then(({ data }) => {
        this.orders = []
        this.orders = [...data['hydra:member']]
        this.isOrdersLoaded = true
      })
    },
    orderOrdersByDate() {
      this.dateOrder.isActive = true
      if (this.dateOrder.query === 'asc') {
        this.dateOrder.query = 'desc'
      } else {
        this.dateOrder.query = 'asc'
      }

      this.isOrdersLoaded = false

      ApiService.get(
          '/api/v1/orders',
          `?company=${this.company}&order[createdAt]=${this.dateOrder.query}`
      ).then(({ data }) => {
        this.orders = []
        this.orders = [...data['hydra:member']]
        this.isOrdersLoaded = true
      })
    },
    orderOrdersByStatus() {
      this.statusOrder.isActive = true
      if (this.statusOrder.query === 'asc') {
        this.statusOrder.query = 'desc'
      } else {
        this.statusOrder.query = 'asc'
      }

      this.isOrdersLoaded = false
      ApiService.get(
          '/api/v1/orders',
          `?company=${this.company}&order[status]=${this.statusOrder.query}`
      ).then(({ data }) => {
        this.orders = []
        this.orders = [...data['hydra:member']]
        this.isOrdersLoaded = true
      })
    },
    showOrderModal(orderId, orderItems) {
      this.isModalShow = true
      this.tempModalOrderId = orderId
      this.tempModalOrderItems = orderItems
    },
    close() {
      this.isModalShow = false
      this.tempModalOrderId = null
      this.tempModalOrderItems = null
    },
  }
}
</script>
