var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "contact__form" }, [
    _vm.showSuccessMessage
      ? _c("div", { staticClass: "contact__form-success-message" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "ms-form-group" }, [
      _c("label", { staticClass: "ms-form-group__label" }, [_vm._v("Ime: *")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.name,
            expression: "name",
          },
        ],
        staticClass: "ms-form-group__input",
        class: { "ms-form-group__input--invalid": _vm.errors.first("name") },
        attrs: { type: "text", name: "name", placeholder: "Unesite ime" },
        domProps: { value: _vm.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.name = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ms-form-group" }, [
      _c("label", { staticClass: "ms-form-group__label" }, [
        _vm._v("Prezime: *"),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.surname,
            expression: "surname",
          },
        ],
        staticClass: "ms-form-group__input",
        class: { "ms-form-group__input--invalid": _vm.errors.first("surname") },
        attrs: {
          type: "text",
          name: "surname",
          placeholder: "Unesite prezime",
        },
        domProps: { value: _vm.surname },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.surname = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ms-form-group" }, [
      _c("label", { staticClass: "ms-form-group__label" }, [
        _vm._v("E-mail adresa: *"),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.email,
            expression: "email",
          },
        ],
        staticClass: "ms-form-group__input",
        class: { "ms-form-group__input--invalid": _vm.errors.first("email") },
        attrs: {
          type: "email",
          name: "email",
          placeholder: "Unesite e-mail adresu",
        },
        domProps: { value: _vm.email },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.email = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ms-form-group" }, [
      _c("label", { staticClass: "ms-form-group__label" }, [_vm._v("Poruka")]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.message,
            expression: "message",
          },
        ],
        staticClass: "ms-form-group__textarea",
        attrs: { rows: "8" },
        domProps: { value: _vm.message },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.message = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "mc-button mc-button--submit",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [_vm._v("Pošalji")]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact__form-success-message--inner" }, [
      _c("span", { staticClass: "c1" }, [
        _vm._v("Hvala Vam na poslanom upitu."),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "c2" }, [
        _vm._v("Uskoro ćemo Vas kontaktirati"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }