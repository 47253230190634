var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "products" }, [
    _c(
      "div",
      { staticClass: "products__header" },
      [
        _c("ProductsQuickSearch", {
          attrs: { "user-username": _vm.userUsername },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "products__body" }, [
      _c(
        "div",
        { staticClass: "products__aside" },
        _vm._l(_vm.filteredAttributes, function (attribute, attrKey) {
          return _c(
            "div",
            { key: attribute.slug, staticClass: "products__aside-group" },
            [
              _c("div", { staticClass: "filter filter--checkbox" }, [
                _c("div", { staticClass: "filter__header" }, [
                  _c("h3", { staticClass: "filter__title" }, [
                    _vm._v(_vm._s(attribute.title)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "filter__body" },
                  _vm._l(attribute.items, function (item, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "filter__form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "checkbox",
                            staticStyle: { "--color": "#fff" },
                            on: {
                              click: function ($event) {
                                return _vm.onFilterClick(item, attrKey)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "checkbox__input" }, [
                              _c("input", { attrs: { type: "checkbox" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkbox__control" }, [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 24 24",
                                      "aria-hidden": "true",
                                      focusable: "false",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        fill: "none",
                                        stroke: "currentColor",
                                        "stroke-width": "4",
                                        d: "M1.73 12.91l6.37 6.37L22.79 4.59",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkbox__label" }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.isProductsLoaded
                          ? _c("div", { staticClass: "filter__dis" })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "products__main" }, [
        _c("div", { staticClass: "products__top" }, [
          _c("h1", { staticClass: "products__title" }, [
            _vm._v(
              "Proizvodi (" + _vm._s(_vm.filteredProducts.length) + ") kom"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "products__top-actions" }, [
            _c(
              "div",
              {
                staticClass: "products__filter-toggle",
                on: { click: _vm.toggleFilters },
              },
              [
                _c("div", { staticClass: "products__filter-toggle-inner" }, [
                  _c("span", { staticClass: "products__filter-toggle-title" }, [
                    _vm._v("Filtriraj"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "products__filter-toggle-icon" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "23",
                          height: "20",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M20.571 2.286H0V0h20.571v2.286zm-8.217 9.143H8v2.285h3.531a6.84 6.84 0 01.823-2.285zm4.789-5.715H3.429V8h13.714V5.714zm0 4.572v3.428h-3.429V16h3.429v3.429h2.286V16h3.428v-2.286H19.43v-3.428h-2.286z",
                            fill: "#000",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.closeSort,
                    expression: "closeSort",
                  },
                ],
                staticClass: "products__sort-toggle",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "products__sort-toggle-inner",
                    on: { click: _vm.toggleSort },
                  },
                  [
                    _c("span", { staticClass: "products__sort-toggle-title" }, [
                      _vm._v("Sortiraj"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "products__sort-toggle-icon",
                        class: "n" ? _vm.activePriceSort : "v",
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "22",
                              height: "19",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M17.6 19l-4.4-4.222h3.3V4.222h-3.3L17.6 0 22 4.222h-3.3v10.556H22L17.6 19zM0 16.889v-2.111h11v2.11H0zm0-6.333V8.444h7.7v2.112H0zm0-6.334v-2.11h4.4v2.11H0z",
                                fill: "#000",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.isSortVisible
                  ? _c(
                      "div",
                      { staticClass: "products__sort-toggle-dropdown" },
                      [
                        _c("ul", [
                          _c(
                            "li",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onSortItemClick("n")
                                },
                              },
                            },
                            [
                              _c("strong", [_vm._v("Cijena:")]),
                              _vm._v(" manja prema većoj"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onSortItemClick("v")
                                },
                              },
                            },
                            [
                              _c("strong", [_vm._v("Cijena:")]),
                              _vm._v(" veća prema manjoj"),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "products__items" },
          [
            !_vm.isProductsLoaded
              ? _c(
                  "div",
                  { staticStyle: { width: "100%", "text-align": "center" } },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "loader",
                        attrs: {
                          version: "1.1",
                          id: "L9",
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          x: "0px",
                          y: "0px",
                          viewBox: "0 0 100 100",
                          "enable-background": "new 0 0 0 0",
                          "xml:space": "preserve",
                        },
                      },
                      [
                        _c(
                          "path",
                          {
                            attrs: {
                              fill: "#DD353D",
                              d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50",
                            },
                          },
                          [
                            _c("animateTransform", {
                              attrs: {
                                attributeName: "transform",
                                attributeType: "XML",
                                type: "rotate",
                                dur: "1s",
                                from: "0 50 50",
                                to: "360 50 50",
                                repeatCount: "indefinite",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.filteredProducts, function (product) {
              return product.hasOwnProperty("category")
                ? _c("ProductItem", {
                    key: product.id,
                    attrs: {
                      product: product,
                      "user-username": _vm.userUsername,
                    },
                  })
                : _vm._e()
            }),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "products__footer" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }