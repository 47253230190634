import Vue from 'vue'

Vue.filter('currencyFormat', function(value) {
  if (value) {
    return parseFloat(value)
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }
})

Vue.filter('euroPrice', function(value) {
  if (value) {
    const price = parseFloat(value).toFixed(2)
    const fixEuro = 7.5345
    const totalPrice = price / fixEuro

    return parseFloat(totalPrice)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }
})

Vue.filter('variation', function(src, size) {
  if (!size) return  '/uploads/' + src
  if (!src) return '/media/cache/resolve/' + size + '/build/images/ms-no-image.jpg'
  return '/media/cache/resolve/' + size + src
})

Vue.filter('highlight', function(word, query) {
  let check = new RegExp(query, "ig");
  return word.toString().replace(check, function(matchedText){
    return ('<strong style="color: #000">' + matchedText + '</strong>');
  });
})
