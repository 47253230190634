<template>
  <div class="mc-products-quick-search">
    <div class="mc-products-quick-search__inner">
      <vue-autosuggest
        ref="autocomplete"
        v-model="query"
        :suggestions="suggestions"
        :input-props="inputProps"
        :section-configs="sectionConfigs"
        :get-suggestion-value="getSuggestionValue"
        @input="fetchResults"
      >
        <template slot-scope="{ suggestion }">
          <div v-if="suggestion.name === 'products'" class="autosuggest__results-item-wrapper">
            <a class="autosuggest__results-item-name" target="_blank" :href="suggestion.item.url">
              <span v-html="$options.filters.highlight(suggestion.item.name, query)" />
              <span v-if="userUsername" v-html="' - '" />
              <span v-if="userUsername" class="autosuggest__results-item-price" v-html="currencyFormat(suggestion.item.price)" />
              <span v-if="userUsername" class="autosuggest__results-item-price-currency" v-html="'HRK'" />
              <span v-if="userUsername">/</span>
              <span v-if="userUsername" class="autosuggest__results-item-price" v-html="euroPrice(suggestion.item.price)"/>
              <span v-if="userUsername" class="autosuggest__results-item-price-currency" v-html="'EUR'" />
            </a>
            <div class="autosuggest__results-item-description" v-html="suggestion.item.shortDescription" />
          </div>
          <div v-if="suggestion.name === 'posts'" class="autosuggest__results-item-wrapper">
            <span class="autosuggest__results-item-content">
              <a target="_blank" :href="suggestion.item.url">
                <span v-html="$options.filters.highlight(suggestion.item.title, query)" />
              </a>
            </span>
          </div>
        </template>
      </vue-autosuggest>
      <div class="mc-products-quick-search__icon">
        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.656 2.145A12.727 12.727 0 0112.727 0v1.176V0a12.728 12.728 0 0112.727 12.727A12.727 12.727 0 115.657 2.145zm7.071.208a10.375 10.375 0 0110.375 10.374M12.727 2.353a10.374 10.374 0 1010.375 10.374" fill="#DD181F"/><path fill-rule="evenodd" clip-rule="evenodd" d="M20.33 20.33a1.129 1.129 0 011.597 0l7.742 7.742a1.13 1.13 0 01-1.596 1.597l-7.742-7.742a1.13 1.13 0 010-1.596z" fill="#DD181F"/></svg>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest';
import axios from 'axios'

export default {
  name: 'ProductsQuickSearch',
  filters: {
    highlight: function(word, query) {
      let check = new RegExp(query, "ig");
      return word.toString().replace(check, function(matchedText){
        return ('<strong style="color: #000">' + matchedText + '</strong>');
      });
    }
  },
  components: {
    VueAutosuggest
  },
  props: ['userUsername'],
  data() {
    return {
      query: "",
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 250,
      productsUrl: `${window.location.origin}/api/v1/products.json?properties[]=name&properties[]=slug&properties[]=shortDescription&properties[]=price&properties[]=category&isPublish=true&itemsPerPage=2000`,
      brandsUrl: `${window.location.origin}/api/v1/brands.json`,
      inputProps: {
        id: "autosuggest__input",
        placeholder: "Pretraži proizvode...",
        class: "mc-quick-search__input",
        name: "Search"
      },
      suggestions: [],
      sectionConfigs: {
        products: {
          limit: 20,
          onSelected: selected => {
            window.location.href = `/proizvodi/${selected.item.category.root.slug}/${selected.item.category.slug}/${selected.item.slug}`
          }
        }
      },

    }
  },
  methods: {
    fetchResults() {
      const query = this.query;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const productsPromise = axios.get(`${window.location.origin}/api/v1/products.json?name=${query.replace('+', '%2b')}&properties[]=name&properties[]=slug&properties[]=shortDescription&properties[]=price&properties[]=category&isPublish=true&itemsPerPage=200`);

        Promise.all([productsPromise]).then(values => {
          this.suggestions = [];
          this.selected = null;

          const products = this.filterResults(values[0].data, query, "name");

          products.length && this.suggestions.push({
            name: "products",
            data: products
          });
        });
      }, this.debounceMilliseconds);
    },
    filterResults(data, text, field) {
      return data
          .filter(item => {
            if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
              return item[field];
            }
          })
          .sort();
    },
    getSuggestionValue(suggestion) {
      let title = suggestion.title;
      let item = suggestion.item;
      return title === "posts" ? item.title : item.name;
    },
    currencyFormat(value) {
      if (value) {
        return parseFloat(value)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      }
    },
    euroPrice(value) {
      if (value) {
        const price = parseFloat(value).toFixed(2)
        const fixEuro = 7.5345
        const totalPrice = price / fixEuro

        return parseFloat(totalPrice)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      }
    },
  }
}
</script>

<style lang="scss">
@import "~include-media/dist/include-media";
@import "../../../../styles/helpers/_variables";
@import "../../../../styles/helpers/mixins";

.mc-products-quick-search {
  position: relative;
  text-align: center;

  #autosuggest {
    width: 100%;
  }

  #autosuggest__input {
    outline: none;
    position: relative;
    width: 100%;
    border: 0;
    font-size: 21px;
    line-height: 36px;
    border-radius: 100px;
    color: #000000;
    background-color: #fff;
    font-weight: 600;
  }

  .autosuggest__label {
    visibility: hidden;
  }

  #autosuggest__input.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .autosuggest__input--open {
    border-radius: 35px 35px 0 0 !important;
  }

  .autosuggest {

    &__results-container {
      position: relative;
      width: 100%;
    }

    &__results {
      margin: 0 auto;
      position: absolute;
      z-index: 10000001;
      width: calc(100%);
      box-sizing: border-box;
      background: #fff;
      text-transform: uppercase;
      color: #000;
      top: 0;
      filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
      border-bottom-left-radius: 35px;
      border-bottom-right-radius: 35px;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: auto;
      }

    }

    &__results-item-wrapper {
      display: flex;
      text-align: left;
      flex-direction: column;
    }

    &__results-item-name {}
    &__results-item-price {
      color: $masterRed500;
      font-style: normal;
      font-weight: bold;
    }
    &__results-item-price-currency {
      color: $masterBlack200;
      font-style: normal;
      font-weight: bold;
      @include fontSize(14);
      @include lineHeight(21, 14);
    }
    &__results-item-description {
      @include fontSize(14);
      @include lineHeight(19, 14);
    }

    &__results-item {
      padding: 15px 45px;
      @include fontSize(18);
      @include lineHeight(23, 18);
      font-style: normal;
      font-weight: normal;
      text-transform: none;
      color: #000000;
      cursor: pointer;

      @media (max-width: 575px) {
        padding: 15px 30px;
      }

      &:active,
      &:hover,
      &:focus { background-color: #F2F2F2; }
      &--highlighted {}
    }

    &__results-item-icon {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      visibility: hidden;
    }

    &__results-item-icon-item-icon {
      &--villa { background-color: rgba(10, 187, 135, 0.1); }
      &--location { background-color: rgba(10, 187, 135, 0.1); }
    }

    &__results-before {
      padding: 15px 45px;
      text-align: left;
      @include fontSize(21);
      @include lineHeight(26, 21);
      font-style: normal;
      font-weight: bold;
      color: #DD181F;
    }
  }

  #autosuggest ul:nth-child(1) > .autosuggest__results_title {
    border-top: none;
  }
}
</style>
