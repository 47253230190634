import axios from "axios"

const ApiService = {

  init() {
   axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    return axios.get(`${resource}/${slug}`)
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return axios.post(`${resource}`, params)
  },

  put(resource, params) {
    return axios.put(`${resource}`, params)
  },

  delete(resource) {
    return axios.delete(resource)
  }
}

export default ApiService
