<template>
  <div class="ms-alert">
    <div class="ms-alert__title">Preuzmi dokumente proizvoda</div>
    <p class="ms-alert__content">Preuzmi dokumente proizvoda u obliku .zip datoteke</p>
    <span class="ms-alert__text-block"></span>
    <button @click.prevent="downloadCartDocuments" class="mc-button mc-button--cart-download">Preuzmi</button>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: 'DownloadInvoiceDocuments',
  props: ['cartId', 'userId'],
  data() {
    return {}
  },
  methods: {
    downloadCartDocuments() {
      ApiService.get('/api/v1/exports/zip/cart-export', this.cartId).then(({ data }) => {
        window.open(`${window.location.origin}${data.response.downloadUrl}`, '_blank')
      })
    }
  }
}
</script>
