<template>
  <section class="mc-brands-row">
    <div class="mc-brands-row__header">
      <h2 class="mc-brands-row__title">
        <span class="mc-brands-row__title-700">{{ title }}</span>
        <span class="mc-brands-row__title-400">{{ subtitle }}</span>
      </h2>
    </div>
    <div class="mc-brands-row__body">
      <div v-for="(brand, key) in entities" :key="key" class="mc-brands__item">
        <a :href="brand.webUrl" target="_blank">
          <img class="mc-brands__brand-img"
               :src="brand.featuredImage.src | variation('160x65')"
               :alt="brand.name"
               loading="lazy">
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Brands',
  filters: {
    variation: function(src, size) {
      if (!size) return  '/uploads/' + src
      if (!src) return '/media/cache/resolve/' + size + '/build/images/ms-no-image.jpg'
      return '/media/cache/resolve/' + size + src
    }
  },
  props: ['title', 'subtitle', 'entities'],
  data() {
    return {
    }
  },
}
</script>
