var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mc-categories" }, [
      _c(
        "div",
        {
          staticClass:
            "mc-categories__row mc-categories__row--wrap mc-categories__row--gap-0",
        },
        _vm._l(_vm.filteredCategories, function (category, key) {
          return _c(
            "div",
            { key: key, staticClass: "mc-category-card mc-category-card--w25" },
            [
              _c("h2", { staticClass: "mc-category-card__title" }, [
                _vm._v(_vm._s(category.title)),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "mc-category-card__list" },
                _vm._l(category.children, function (childCategory, key) {
                  return _c(
                    "li",
                    { key: key, staticClass: "mc-category-card__item" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "mc-category-card__link",
                          attrs: {
                            href:
                              "/proizvodi/" +
                              category.slug +
                              "/" +
                              childCategory.slug,
                          },
                        },
                        [_vm._v(_vm._s(childCategory.title))]
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "mc-category-card__origin",
                  attrs: { href: "/proizvodi/" + category.slug },
                },
                [_vm._v("\n          Pogledaj sve...\n        ")]
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }