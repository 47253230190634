<template>
  <div class="mc-products-row">
    <div class="mc-products-row__header">
      <h2 class="mc-products-row__title">
        <span v-if="title" class="mc-products-row__title-400">{{ title }}</span>
        <span v-if="subtitle" class="mc-products-row__title-700">{{ subtitle }}</span>
      </h2>
    </div>
    <div class="mc-products-row__body">
      <div class="products-slider">
        <swiper
            ref="productsSlider"
            :options="swiperOptions">
          <swiper-slide
              v-if="isProductsLoaded"
              v-for="(entity, key) in entities"
              :key="key">
            <article class="mc-product">
              <div class="mc-product__header">
                <img class="mc-product__image"
                     :src="entity.featuredImage.src | variation('240x240')"
                     :alt="entity.name"
                     loading="lazy">
              </div>
              <div class="mc-product__body">
                <h4 v-if="entity.brand" class="mc-product__brand">{{ entity.brand.name }}</h4>
                <h3 class="mc-product__title">{{ entity.name }}</h3>
                <p class="mc-product__short-description">{{ entity.shortDescription }}</p>
                <div v-if="userUsername" class="mc-product__price-wrapper">
                  <div>
                    <span class="mc-product__price">{{ entity.price | currencyFormat }}</span>
                    <span class="mc-product__currency">hrk</span>
                  </div>
                  <div>
                    <span class="mc-product__price">{{ entity.price | euroPrice }}</span>
                    <span class="mc-product__currency">eur</span>
                  </div>
                </div>
                <div v-else-if="entity.isB2C" class="mc-product__price-wrapper">
                  <div>
                    <span class="mc-product__price">{{ entity.price | currencyFormat }}</span>
                    <span class="mc-product__currency">hrk</span>
                  </div>
                  <div>
                    <span class="mc-product__price">{{ entity.price | euroPrice }}</span>
                    <span class="mc-product__currency">eur</span>
                  </div>
                </div>
                <div v-else class="price-login-alert price-login-alert--mc-product">
                  <div class="price-login-alert__inner">
                    <span class="price-login-alert__icon">
                      <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.3 4.9h1.4V3.5H6.3v1.4zm.7 7.7A5.607 5.607 0 011.4 7c0-3.087 2.513-5.6 5.6-5.6 3.087 0 5.6 2.513 5.6 5.6 0 3.087-2.513 5.6-5.6 5.6zM7 0a7 7 0 100 14A7 7 0 007 0zm-.7 10.5h1.4V6.3H6.3v4.2z" fill="#DD181F"></path></svg>
                    </span>
                    <span class="price-login-alert__text">Za prikaz cijene, potrebna je prijava.</span>
                  </div>
                </div>
              </div>
              <a :href="'/proizvodi/category/sub-category/' + entity.slug" class="mc-product__link"></a>
              <div class="mc-product__footer">
<!--                <button-->
<!--                    @click="addCartItem(entity)"-->
<!--                    class="mc-add-to-cart"-->
<!--                    :disabled="!userUsername && !entity.isB2C"-->
<!--                >Dodaj u košaricu</button>-->
              </div>
            </article>
          </swiper-slide>

          <swiper-slide v-else>
            <article class="mc-product mc-product--loading">
              <div class="mc-product__header">
                <img class="mc-product__image"
                     src="/media/cache/resolve/240x240/build/images/ms-no-image.jpg"
                     alt="Loading product..."
                     loading="lazy">
              </div>
              <div class="mc-product__body">
                <h3 class="mc-product__title"></h3>
                <div class="price-login-alert price-login-alert--mc-product">
                  <div class="price-login-alert__inner"></div>
                </div>
              </div>
              <div class="mc-product__footer">
                <button class="mc-add-to-cart"></button>
              </div>
            </article>
          </swiper-slide>
          <swiper-slide v-else>
            <article class="mc-product mc-product--loading">
              <div class="mc-product__header">
                <img class="mc-product__image"
                     src="/media/cache/resolve/240x240/build/images/ms-no-image.jpg"
                     alt="Loading product..."
                     loading="lazy">
              </div>
              <div class="mc-product__body">
                <h3 class="mc-product__title"></h3>
                <div class="price-login-alert price-login-alert--mc-product">
                  <div class="price-login-alert__inner"></div>
                </div>
              </div>
              <div class="mc-product__footer">
                <button class="mc-add-to-cart"></button>
              </div>
            </article>
          </swiper-slide>
          <swiper-slide v-else>
            <article class="mc-product mc-product--loading">
              <div class="mc-product__header">
                <img class="mc-product__image"
                     src="/media/cache/resolve/240x240/build/images/ms-no-image.jpg"
                     alt="Loading product..."
                     loading="lazy">
              </div>
              <div class="mc-product__body">
                <h3 class="mc-product__title"></h3>
                <div class="price-login-alert price-login-alert--mc-product">
                  <div class="price-login-alert__inner"></div>
                </div>
              </div>
              <div class="mc-product__footer">
                <button class="mc-add-to-cart"></button>
              </div>
            </article>
          </swiper-slide>
          <swiper-slide v-else>
            <article class="mc-product mc-product--loading">
              <div class="mc-product__header">
                <img class="mc-product__image"
                     src="/media/cache/resolve/240x240/build/images/ms-no-image.jpg"
                     alt="Loading product..."
                     loading="lazy">
              </div>
              <div class="mc-product__body">
                <h3 class="mc-product__title"></h3>
                <div class="price-login-alert price-login-alert--mc-product">
                  <div class="price-login-alert__inner"></div>
                </div>
              </div>
              <div class="mc-product__footer">
                <button class="mc-add-to-cart"></button>
              </div>
            </article>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import {directive, Swiper, SwiperSlide} from "vue-awesome-swiper";

export default {
  filters: {
    variation: function(src, size) {
      if (!size) return  '/uploads/' + src
      if (!src) return '/media/cache/resolve/' + size + '/build/images/ms-no-image.jpg'
      return '/media/cache/resolve/' + size + src
    }
  },
  props: ['title', 'subtitle', 'entities', 'userUsername'],
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      products: [],
      isProductsLoaded: false,
      swiperOptions: {
        slidesPerView: 1.4,
        spaceBetween: 10,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.productsSlider.$swiper
    }
  },
  mounted() {
    setTimeout(() => {
      this.isProductsLoaded = true
    }, 200)
  }
}
</script>

<style lang="scss" scoped>
.products-slider {
  &__inner {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1200px;
  }

  &__header {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__title {
    font-family: 'Playfair Display', serif;
    font-size: 46px;
    line-height: 54px;
    text-align: center;
    color: #668ca4;
  }
  &__subtitle {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #50504f;
  }
  &__body {
    padding: 30px 0;
    display: flex;
    gap: 20px;
  }
  &__footer {
  }
}
</style>
