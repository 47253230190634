import $ from 'jquery'

export default class DeliveryRadioSubmitForm {
    constructor(element) {
        $('.delivery_delivery_0').change(() => {
            $("form[name='delivery']").submit();
        });
        $('.delivery_delivery_1').change(() => {
            $("form[name='delivery']").submit();
        });
    }
}
