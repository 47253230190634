var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mc-products-row" }, [
    _c("div", { staticClass: "mc-products-row__header" }, [
      _c("h2", { staticClass: "mc-products-row__title" }, [
        _vm.title
          ? _c("span", { staticClass: "mc-products-row__title-400" }, [
              _vm._v(_vm._s(_vm.title)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.subtitle
          ? _c("span", { staticClass: "mc-products-row__title-700" }, [
              _vm._v(_vm._s(_vm.subtitle)),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mc-products-row__body" }, [
      _c(
        "div",
        { staticClass: "products-slider" },
        [
          _c(
            "swiper",
            { ref: "productsSlider", attrs: { options: _vm.swiperOptions } },
            [
              _vm._l(_vm.entities, function (entity, key) {
                return _vm.isProductsLoaded
                  ? _c("swiper-slide", { key: key }, [
                      _c("article", { staticClass: "mc-product" }, [
                        _c("div", { staticClass: "mc-product__header" }, [
                          _c("img", {
                            staticClass: "mc-product__image",
                            attrs: {
                              src: _vm._f("variation")(
                                entity.featuredImage.src,
                                "240x240"
                              ),
                              alt: entity.name,
                              loading: "lazy",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mc-product__body" }, [
                          entity.brand
                            ? _c("h4", { staticClass: "mc-product__brand" }, [
                                _vm._v(_vm._s(entity.brand.name)),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("h3", { staticClass: "mc-product__title" }, [
                            _vm._v(_vm._s(entity.name)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "mc-product__short-description" },
                            [_vm._v(_vm._s(entity.shortDescription))]
                          ),
                          _vm._v(" "),
                          _vm.userUsername
                            ? _c(
                                "div",
                                { staticClass: "mc-product__price-wrapper" },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "mc-product__price" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currencyFormat")(
                                              entity.price
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "mc-product__currency" },
                                      [_vm._v("hrk")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "mc-product__price" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("euroPrice")(entity.price)
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "mc-product__currency" },
                                      [_vm._v("eur")]
                                    ),
                                  ]),
                                ]
                              )
                            : entity.isB2C
                            ? _c(
                                "div",
                                { staticClass: "mc-product__price-wrapper" },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "mc-product__price" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currencyFormat")(
                                              entity.price
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "mc-product__currency" },
                                      [_vm._v("hrk")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "mc-product__price" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("euroPrice")(entity.price)
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "mc-product__currency" },
                                      [_vm._v("eur")]
                                    ),
                                  ]),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "price-login-alert price-login-alert--mc-product",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "price-login-alert__inner" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "price-login-alert__icon",
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                width: "14",
                                                height: "14",
                                                fill: "none",
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d: "M6.3 4.9h1.4V3.5H6.3v1.4zm.7 7.7A5.607 5.607 0 011.4 7c0-3.087 2.513-5.6 5.6-5.6 3.087 0 5.6 2.513 5.6 5.6 0 3.087-2.513 5.6-5.6 5.6zM7 0a7 7 0 100 14A7 7 0 007 0zm-.7 10.5h1.4V6.3H6.3v4.2z",
                                                  fill: "#DD181F",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "price-login-alert__text",
                                        },
                                        [
                                          _vm._v(
                                            "Za prikaz cijene, potrebna je prijava."
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                        ]),
                        _vm._v(" "),
                        _c("a", {
                          staticClass: "mc-product__link",
                          attrs: {
                            href:
                              "/proizvodi/category/sub-category/" + entity.slug,
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "mc-product__footer" }),
                      ]),
                    ])
                  : _c("swiper-slide", [
                      _c(
                        "article",
                        { staticClass: "mc-product mc-product--loading" },
                        [
                          _c("div", { staticClass: "mc-product__header" }, [
                            _c("img", {
                              staticClass: "mc-product__image",
                              attrs: {
                                src: "/media/cache/resolve/240x240/build/images/ms-no-image.jpg",
                                alt: "Loading product...",
                                loading: "lazy",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mc-product__body" }, [
                            _c("h3", { staticClass: "mc-product__title" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "price-login-alert price-login-alert--mc-product",
                              },
                              [
                                _c("div", {
                                  staticClass: "price-login-alert__inner",
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mc-product__footer" }, [
                            _c("button", { staticClass: "mc-add-to-cart" }),
                          ]),
                        ]
                      ),
                    ])
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "swiper-pagination",
                attrs: { slot: "pagination" },
                slot: "pagination",
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }