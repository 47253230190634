<template>
  <div>
    <div class="mc-categories">
      <div class="mc-categories__row mc-categories__row--wrap mc-categories__row--gap-0">
        <div
          v-for="(category, key) in filteredCategories"
          :key="key"
          class="mc-category-card mc-category-card--w25">
          <h2 class="mc-category-card__title">{{ category.title }}</h2>
          <ul class="mc-category-card__list">
            <li v-for="(childCategory, key) in category.children" :key="key"  class="mc-category-card__item"><a class="mc-category-card__link" :href="'/proizvodi/' + category.slug + '/' + childCategory.slug">{{ childCategory.title }}</a></li>
          </ul>
          <a
            class="mc-category-card__origin"
            :href="'/proizvodi/' + category.slug">
            Pogledaj sve...
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: 'ProductCategories',
  data() {
    return {
      categories: [],
      filteredCategories: [],
      isCategoriesLoaded: false,
    }
  },
  mounted() {
    this.getProductsCategories('/api/v1/product_categories',
        '?order[createdAt]=desc&order[position]=asc&itemsPerPage=90').then(({ data }) => {
      this.categories = [...data['hydra:member']]
      this.categories.forEach(category => {
        if (category['@id'] === category.root) {
          this.filteredCategories.push({
            title: category.title,
            slug: category.slug,
            children: []
          })
        }
      })
      this.categories.forEach(category => {
        if (category['@id'] !== category.root) {
          this.filteredCategories.forEach((filteredCategory, key) => {
            if (category.parent.slug === filteredCategory.slug) {
              this.filteredCategories[key].children.push(category)
            }
          })
        }
      })
    })
  },
  methods: {
    async getProductsCategories(endpoint, filter) {
      try {
        return await ApiService.get(endpoint, filter)
      } catch (e) {
        throw new Error(e)
      }
    }
  },
}
</script>
