var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-orders__table-wrapper" }, [
    _vm.isOrdersLoaded
      ? _c(
          "div",
          { staticClass: "table" },
          [
            _c("div", { staticClass: "tr th tr--top" }, [
              _c("div", { staticClass: "td text-left" }, [
                _c(
                  "span",
                  {
                    staticClass: "cursor-pointer",
                    on: { click: _vm.orderOrdersById },
                  },
                  [
                    _c(
                      "span",
                      { class: { "text-primary": _vm.idOrder.isActive } },
                      [_vm._v("#ID")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "td" }, [
                _c(
                  "span",
                  {
                    staticClass: "cursor-pointer",
                    on: { click: _vm.orderOrdersByDate },
                  },
                  [
                    _c(
                      "span",
                      { class: { "text-primary": _vm.dateOrder.isActive } },
                      [_vm._v("Datum")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "td", staticStyle: { "min-width": "106px" } },
                [_vm._v("\n        Broj artikla\n      ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "td" }, [
                _vm._v("\n        Cijena\n      "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "td" }, [
                _c(
                  "span",
                  {
                    staticClass: "cursor-pointer",
                    on: { click: _vm.orderOrdersByStatus },
                  },
                  [
                    _c(
                      "span",
                      { class: { "text-primary": _vm.statusOrder.isActive } },
                      [_vm._v("Status")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "td text-right" }, [
                _vm._v("\n        Opcije\n      "),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.orders, function (order, key) {
              return _c("div", { key: key, staticClass: "tr" }, [
                _c("div", { staticClass: "td" }, [
                  _c("span", [_c("strong", [_vm._v("#" + _vm._s(order.id))])]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "td",
                    staticStyle: { "justify-content": "center" },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(order.createdAt))),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "td",
                    staticStyle: { "justify-content": "center" },
                  },
                  [
                    _c("span", [
                      _c("span", [_vm._v(_vm._s(order.items.length) + " kom")]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "td",
                    staticStyle: { "justify-content": "center" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("currencyFormat")(order.total)) +
                              " kn"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("euroPrice")(order.total)) + " €"
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "td",
                    staticStyle: { "justify-content": "center" },
                  },
                  [
                    order.status === "cart"
                      ? _c(
                          "span",
                          { staticClass: "mc-pill mc-pill--invoice" },
                          [_vm._v("ponuda")]
                        )
                      : _c("span", { staticClass: "mc-pill mc-pill--order" }, [
                          _vm._v("naručeno"),
                        ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "td",
                    staticStyle: { "justify-content": "flex-end" },
                  },
                  [
                    order.status === "cart"
                      ? _c("span", { staticClass: "mc-icon" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/moje-narudzbe/" + order.id,
                                target: "_blank",
                                title: "Pogledaj",
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "24",
                                    height: "24",
                                    viewBox: "0 0 24 24",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "mc-icon" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "api/v1/exports/pdf/cart-export/" +
                              order.id +
                              "/" +
                              _vm.userId +
                              "/-1/0",
                            target: "_blank",
                            title: "S popustom",
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M11.363 2c4.155 0 2.637 6 2.637 6s6-1.65 6 2.457v11.543h-16v-20h7.363zm.826-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784zm-4.9 0h-1.599v3.686h1.599c.537 0 .961-.181 1.262-.535.555-.658.587-2.034-.062-2.692-.298-.3-.712-.459-1.2-.459zm-.692.783h.496c.473 0 .802.173.915.644.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12zm-2.74-.783h-1.668v3.686h.907v-1.277h.761c.619 0 1.064-.277 1.224-.763.095-.291.095-.597 0-.885-.16-.484-.606-.761-1.224-.761zm-.761.732h.546c.235 0 .467.028.576.228.067.123.067.366 0 .489-.109.199-.341.227-.576.227h-.546v-.944z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mc-icon" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "api/v1/exports/pdf/cart-export/" +
                              order.id +
                              "/" +
                              _vm.userId +
                              "/0/-1",
                            target: "_blank",
                            title: "Bez popusta",
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M12.819 14.427c.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12h.496c.474 0 .803.173.916.644zm3.091-8.65c2.047-.479 4.805.279 6.09 1.179-1.494-1.997-5.23-5.708-7.432-6.882 1.157 1.168 1.563 4.235 1.342 5.703zm-7.457 7.955h-.546v.943h.546c.235 0 .467-.027.576-.227.067-.123.067-.366 0-.489-.109-.198-.341-.227-.576-.227zm13.547-2.732v13h-20v-24h8.409c4.858 0 3.334 8 3.334 8 3.011-.745 8.257-.42 8.257 3zm-12.108 2.761c-.16-.484-.606-.761-1.224-.761h-1.668v3.686h.907v-1.277h.761c.619 0 1.064-.277 1.224-.763.094-.292.094-.597 0-.885zm3.407-.303c-.297-.299-.711-.458-1.199-.458h-1.599v3.686h1.599c.537 0 .961-.181 1.262-.535.554-.659.586-2.035-.063-2.693zm3.701-.458h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("OrderModal", {
          attrs: {
            "is-show": _vm.isModalShow,
            "order-id": _vm.tempModalOrderId,
            items: _vm.tempModalOrderItems,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }