<template>
  <section v-if="entities.length > 0" class="mc-news-row">
    <div class="mc-news-row__header">
      <h2 class="mc-news-row__title">
        <span v-if="title" class="mc-products-row__title-400">{{ title }}</span>
        <span v-if="subtitle" class="mc-products-row__title-700">{{ subtitle }}</span>
      </h2>
      <a class="mc-news-row__origin" href="/novosti">Pogledaj sve novosti</a>
    </div>
    <div class="mc-news-row__body mc-news-row__body--slider">
      <PostsCarousel :entities="entities" :is-loaded="isLoaded"/>
    </div>
  </section>
</template>

<script>
import ApiService from "../../services/api.service";
import PostsCarousel from "../posts-carousel/PostsCarousel";

export default {
  name: 'LatestNews',
  components: {
    PostsCarousel
  },
  props: ['title', 'subtitle'],
  data() {
    return {
      entities: [],
      isLoaded: false
    }
  },
  mounted() {
    ApiService.get('/api/v1/posts', '?isPublish=true&order[createdAt]=desc&itemsPerPage=3').then(({ data }) => {
      this.entities = [...data['hydra:member']]

      setTimeout(() => {
        this.isLoaded = true
      }, 2000)
    })
  },
  methods: {},
}
</script>
