var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "register__form" }, [
    _c("div", { staticClass: "ms-form-group" }, [
      _c("label", { staticClass: "ms-form-group__label" }, [
        _vm._v("Ime firme: *"),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.companyName,
            expression: "companyName",
          },
        ],
        staticClass: "ms-form-group__input",
        class: {
          "ms-form-group__input--invalid": _vm.errors.first("companyName"),
        },
        attrs: {
          name: "companyName",
          type: "text",
          placeholder: "Unesite ime firme",
        },
        domProps: { value: _vm.companyName },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.companyName = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ms-form-group" }, [
      _c("label", { staticClass: "ms-form-group__label" }, [_vm._v("OIB: *")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.oib,
            expression: "oib",
          },
        ],
        staticClass: "ms-form-group__input",
        class: { "ms-form-group__input--invalid": _vm.errors.first("oib") },
        attrs: {
          name: "oib",
          type: "number",
          placeholder: "Unesite OIB firme",
        },
        domProps: { value: _vm.oib },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.oib = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ms-form-group" }, [
      _c("label", { staticClass: "ms-form-group__label" }, [
        _vm._v("Telefon: *"),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.phone,
            expression: "phone",
          },
        ],
        staticClass: "ms-form-group__input",
        class: { "ms-form-group__input--invalid": _vm.errors.first("phone") },
        attrs: {
          name: "phone",
          type: "text",
          placeholder: "Unesite telefon kupca",
        },
        domProps: { value: _vm.phone },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.phone = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ms-form-group" }, [
      _c("label", { staticClass: "ms-form-group__label" }, [
        _vm._v("E-mail adresa: *"),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.email,
            expression: "email",
          },
        ],
        staticClass: "ms-form-group__input",
        class: { "ms-form-group__input--invalid": _vm.errors.first("email") },
        attrs: {
          name: "email",
          type: "email",
          placeholder: "Unesite e-mail adresu",
        },
        domProps: { value: _vm.email },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.email = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "mc-button mc-button--register",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [_vm._v("Pošalji")]
    ),
    _vm._v(" "),
    _vm.showSuccessMessage
      ? _c("div", { staticClass: "msg" }, [_c("span", [_vm._v("Loading...")])])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ms-form-group" }, [
      _c("small", [
        _vm._v(
          "Vaši osobni podaci će biti upotrijebljeni kako bi poboljšali vaše korisničko iskustvo na našim stranicama i za druge svrhe opisane u našim\n      "
        ),
        _c(
          "a",
          {
            staticClass: "ms-form-group__link",
            attrs: { href: "/pravila-privatnosti" },
          },
          [_vm._v("pravilima privatnosti.")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }