<template>
  <form class="contact__form">
    <div v-if="showSuccessMessage" class="contact__form-success-message">
      <div class="contact__form-success-message--inner">
        <span class="c1">Hvala Vam na poslanom upitu.</span>
        <br>
        <span class="c2">Uskoro ćemo Vas kontaktirati</span>
      </div>
    </div>
    <div class="ms-form-group">
      <label class="ms-form-group__label">Ime: *</label>
      <input
        v-validate="'required'"
        v-model="name"
        class="ms-form-group__input"
        type="text"
        name="name"
        placeholder="Unesite ime"
        :class="{'ms-form-group__input--invalid': errors.first('name')}"
      >
    </div>
    <div class="ms-form-group">
      <label class="ms-form-group__label">Prezime: *</label>
      <input
        v-validate="'required'"
        v-model="surname"
        class="ms-form-group__input"
        type="text"
        name="surname"
        placeholder="Unesite prezime"
        :class="{'ms-form-group__input--invalid': errors.first('surname')}"
      >
    </div>
    <div class="ms-form-group">
      <label class="ms-form-group__label">E-mail adresa: *</label>
      <input
        v-validate="'required'"
        v-model="email"
        class="ms-form-group__input"
        type="email"
        name="email"
        placeholder="Unesite e-mail adresu"
        :class="{'ms-form-group__input--invalid': errors.first('email')}"
      >
    </div>
    <div class="ms-form-group">
      <label class="ms-form-group__label">Poruka</label>
      <textarea v-model="message" class="ms-form-group__textarea" rows="8"></textarea>
    </div>
    <button @click.prevent="submit" class="mc-button mc-button--submit">Pošalji</button>
  </form>
</template>

<script>
import ApiService from "../../services/api.service"

export default {
  name: 'ContactForm',
  data() {
    return {
      name: null,
      surname: null,
      email: null,
      message: null,
      isOpen: false,
      showSuccessMessage: false
    }
  },
  methods: {
    submit() {
      ApiService.post('/api/v1/contacts', {
        name: this.name,
        surname: this.surname,
        email: this.email,
        message: this.message,
        isOpen: this.isOpen
      }).then(response => {
        if (response.status === 201) {
          this.name = null
          this.surname = null
          this.email = null
          this.message = null

          this.showSuccessMessage = true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
