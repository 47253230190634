import '../styles/app.scss'
require('./_vue/vue');
import MobileMenu from "./_components/MobileMenu.component";
import DeliveryRadioSubmitForm from "./_components/DeliveryRadioSubmitForm.component";

const components = [
    {
        class: MobileMenu,
        selector: '.mc-mobile-ham'
    },
    {
        class: DeliveryRadioSubmitForm,
        selector: '.delivery_delivery_1'
    }
];

components.forEach(component =>{
    if (document.querySelector(component.selector) !== null) {
        document.querySelectorAll(component.selector).forEach(
            element => new component.class(element, component.options)
        );
    }
});
