<template>
  <div  class="mc-product-banner" style="background: url('build/images/banners/product-banner-01.jpg') no-repeat center center;">
    <h3 class="mc-product-banner__title">Osjećaj <br> sigurnosti <br> u vlastitom <br> domu.</h3>
    <article class="mc-product mc-product--w25">
      <div class="mc-product__info mc-product__info--discount">-30%</div>
      <div class="mc-product__header">
        <img class="mc-product__image"
             src="build/images/products/200x200/product-01.png"
             alt="Product title"
             loading="lazy">
      </div>
      <div class="mc-product__body">
        <h4 class="mc-product__brand">Dahua</h4>
        <h3 class="mc-product__title">Video nadzor NVR-1B04-4P</h3>
        <div class="mc-product__price-wrapper">
          <span class="mc-product__price">5.505,00</span>
          <span class="mc-product__currency">hrk</span>
        </div>
      </div>
      <a href="#" class="mc-product__link"></a>
      <div class="mc-product__footer">
        <button class="mc-add-to-cart mc-add-to-cart--red">Dodaj u košaricu</button>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ProductBanner',
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>
