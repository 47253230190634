var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ms-alert" }, [
    _c("div", { staticClass: "ms-alert__title" }, [
      _vm._v("Preuzmi dokumente proizvoda"),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "ms-alert__content" }, [
      _vm._v("Preuzmi dokumente proizvoda u obliku .zip datoteke"),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "ms-alert__text-block" }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "mc-button mc-button--cart-download",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.downloadCartDocuments.apply(null, arguments)
          },
        },
      },
      [_vm._v("Preuzmi")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }