<template>
  <div class="posts-slider">
    <swiper
      ref="postsSlider"
      :options="swiperOptions">
      <swiper-slide
        v-if="isLoaded"
        v-for="(entity, key) in entities"
        :key="key">
        <article class="mc-post-card">
          <div class="mc-post-card__header">
            <img class="mc-post-card__image"
               :src="entity.featuredImage.src | variation('350x200')"
               :alt="entity.title"
               loading="lazy">
          </div>
          <div class="mc-post-card__body">
            <div class="mc-post-card__meta-wrapper">
              <span class="mc-post-card__date">{{ entity.createdAt | formatDate }}</span>
            </div>
            <h3 class="mc-post-card__title">{{ entity.title }}</h3>
            <p v-if="entity.shortDescription" class="mc-post-card__content" v-html="entity.shortDescription"></p>
          </div>
          <a :href="'/novosti/' + entity.slug" class="mc-post-card__link"></a>
        </article>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import format from 'date-fns/format'

export default {
  name: 'PostsCarousel',
  filters: {
    formatDate: function (value) {
      if (value) {
        return format(new Date(value), 'dd.MM.yyyy.')
      }
    },
    truncate: function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
    },
    variation: function(src, size) {
      if (!size) return  '/uploads/' + src
      if (!src) return '/media/cache/resolve/' + size + '/build/images/ms-no-image.jpg'
      return '/media/cache/resolve/' + size + src
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    entities: {
      type: Array,
      required: true
    },
    isLoaded: {
      type: Boolean,
      required: true
    },
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 15,
        loop: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          640: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.postsSlider.$swiper
    }
  },
  methods: {
    truncate(text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
    }
  }
}
</script>

<style lang="scss">
@import "~include-media/dist/include-media";

.posts-slider {
  padding: 30px 0;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    padding: 0 30px;
  }

  .swiper-wrapper {
    padding: 0 0 45px 0;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 0 0 60px 0;
    }
  }

  .swiper-slide-active {

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      //margin-left: 30px;
      filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
    }
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #DD181F;
  }
}
</style>
