<template>
  <main class="homepage">
    <section
        v-for="(section, key) in configuration"
        :key="key"
        :class="['section', `section--${section.slug}`]"
    >
      <MainSlider
        v-if="section.slug === 'main-slider'"
        :title="section.payload.title"
        :subtitle="section.payload.subtitle"
        :entities="section.payload.entities"
        :user-username="userUsername"
      />

      <ProductsSlider
        v-if="section.slug === 'products-slider'"
        :title="section.payload.title"
        :subtitle="section.payload.subtitle"
        :entities="section.payload.entities"
        :user-username="userUsername"
      />

      <LatestNews
        v-if="section.slug === 'latest-news'"
        :title="section.payload.title"
        :subtitle="section.payload.subtitle"
      />

      <QuickSearch
        v-if="section.slug === 'quick-search'"
        :user-username="userUsername"
      />

      <ProductCategories v-if="section.slug === 'product-categories'"/>

      <ProductBanner v-if="section.slug === 'product-banner'"/>

      <Brands
        v-if="section.slug === 'brands'"
        :title="section.payload.title"
        :subtitle="section.payload.subtitle"
        :entities="section.payload.entities"
      />

    </section>

  </main>
</template>

<script>
import ApiService from "../services/api.service";
import MainSlider from "../components/main-slider/MainSlider"
import QuickSearch from "../components/quick-search/QuickSearch"
import ProductsSlider from "../components/products-slider/ProductsSlider"
import LatestNews from "../components/latest-news/LatestNews"
import ProductCategories from "../components/product-categories/ProductCategories"
import ProductBanner from "../components/product-banner/ProductBanner"
import Brands from "../components/brands/Brands"

export default {
  name: 'Homepage',
  components: {
    MainSlider,
    ProductsSlider,
    QuickSearch,
    LatestNews,
    ProductCategories,
    ProductBanner,
    Brands
  },
  props: {
    userUsername: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      configuration: [],
    }
  },
  mounted() {
    this.getPageConfiguration('/api/v1/pages', '?slug=/').then(({ data }) => {
      this.configuration = data['hydra:member'][0].configuration.section
    })
  },
  methods: {
    async getPageConfiguration(endpoint, filter) {
      try {
        return await ApiService.get(endpoint, filter)
      } catch (e) {
        throw new Error(e)
      }
    }
  },
}
</script>
