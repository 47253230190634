export default class MobileMenu {
    constructor() {
        this.trigger = '.mc-mobile-ham'
        this.menu = '.main-menu'
        this.isOpen = false

        document.querySelector('.mc-mobile-ham').addEventListener('click', this.toggle)
        document.querySelector('.js-main-menu-close').addEventListener('click', this.toggle)
    }

    toggle() {
        const self = this
        self.isOpen = !self.isOpen
        if (!self.isOpen) {
            document.querySelector('body').classList.remove('main-menu-show')
        } else {
            document.querySelector('body').classList.add('main-menu-show')
        }
    }
}
