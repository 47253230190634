var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "products-filter" }, [
    _c("div", { staticClass: "products-filter__header" }, [
      _c("span", { staticClass: "products-filter__icon" }, [
        _c(
          "svg",
          {
            attrs: {
              width: "40",
              height: "34",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M36 4H0V0h36v4zM21.62 20H14v4h6.18c.24-1.44.74-2.78 1.44-4zM30 10H6v4h24v-4zm0 8v6h-6v4h6v6h4v-6h6v-4h-6v-6h-4z",
                fill: "#BFBFBF",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "products-filter__title" }, [
        _vm._v("Filter proizvoda"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "products-filter__arrow", on: { click: _vm.toggle } },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "24",
                height: "15",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M2.82 0L12 9.279 21.18 0 24 2.87 12 15 0 2.87 2.82 0z",
                  fill: "#000",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.isOpen
      ? _c("div", { staticClass: "products-filter__body" }, [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "products-filter__search" }, [
      _c("input", {
        staticClass: "products-filter__search-input",
        attrs: { type: "text", placeholder: "Pretraži proizvode..." },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }