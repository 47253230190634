var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c("div", { staticClass: "order-modal" }, [
        _c("div", { staticClass: "order-modal__inner" }, [
          _c("h1", [_vm._v("Order Modal")]),
          _vm._v(" "),
          _c("h2", [_vm._v(_vm._s(_vm.orderId))]),
          _vm._v(" "),
          _c("div", [
            _c(
              "ul",
              _vm._l(_vm.items, function (item, key) {
                return _c("li", { key: key }, [
                  _c("div", [
                    _c("strong", [_vm._v("Name: ")]),
                    _vm._v(_vm._s(item.product.name)),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("strong", [_vm._v("Quantity: ")]),
                    _vm._v(_vm._s(item.quantity)),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("strong", [_vm._v("Price: ")]),
                    _vm._v(
                      _vm._s(_vm._f("currencyFormat")(item.product.price)) +
                        " kn"
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }