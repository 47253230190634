var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "mc-product mc-product--w33" }, [
    _c("div", { staticClass: "mc-product__header" }, [
      _c("img", {
        staticClass: "mc-product__image",
        attrs: {
          src: _vm._f("variation")(_vm.product.featuredImage.src, "240x240"),
          alt: _vm.product.name,
          loading: "lazy",
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mc-product__body" }, [
      _vm.product.brand
        ? _c("h4", { staticClass: "mc-product__brand" }, [
            _vm._v(_vm._s(_vm.product.brand.name)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h3", { staticClass: "mc-product__title" }, [
        _vm._v(_vm._s(_vm.product.name)),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mc-product__short-description" }, [
        _vm._v(_vm._s(_vm.product.shortDescription)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-wrap": "wrap",
            "margin-bottom": "5px",
          },
        },
        _vm._l(
          _vm.orderBy(_vm.product.attributes, "root.position"),
          function (attribute, key) {
            return _c(
              "span",
              {
                key: key,
                staticStyle: {
                  color: "#fff",
                  "background-color": "#DD181F",
                  "margin-right": "6px",
                  "margin-bottom": "6px",
                  padding: "3px 6px",
                  "font-size": "0.8em",
                  "border-radius": "2px",
                },
                attrs: { "data-order": attribute.root.position },
              },
              [_vm._v(_vm._s(attribute.title))]
            )
          }
        ),
        0
      ),
      _vm._v(" "),
      _vm.userUsername
        ? _c("div", { staticClass: "mc-product__price-wrapper" }, [
            _c("div", { staticClass: "mc-product__price-kn" }, [
              _c("span", { staticClass: "mc-product__price" }, [
                _vm._v(_vm._s(_vm._f("currencyFormat")(_vm.product.price))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "mc-product__currency" }, [
                _vm._v("hrk"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mc-product__price-eu" }, [
              _c("span", { staticClass: "mc-product__price" }, [
                _vm._v(_vm._s(_vm._f("euroPrice")(_vm.product.price))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "mc-product__currency" }, [
                _vm._v("eur"),
              ]),
            ]),
          ])
        : _vm.product.isB2C
        ? _c("div", { staticClass: "mc-product__price-wrapper" }, [
            _c("div", { staticClass: "mc-product__price-kn" }, [
              _c("span", { staticClass: "mc-product__price" }, [
                _vm._v(_vm._s(_vm._f("currencyFormat")(_vm.product.price))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "mc-product__currency" }, [
                _vm._v("hrk"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mc-product__price-eu" }, [
              _c("span", { staticClass: "mc-product__price" }, [
                _vm._v(_vm._s(_vm._f("euroPrice")(_vm.product.price))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "mc-product__currency" }, [
                _vm._v("eur"),
              ]),
            ]),
          ])
        : _c(
            "div",
            { staticClass: "price-login-alert price-login-alert--mc-product" },
            [
              _c("div", { staticClass: "price-login-alert__inner" }, [
                _c("span", { staticClass: "price-login-alert__icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "14",
                        height: "14",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M6.3 4.9h1.4V3.5H6.3v1.4zm.7 7.7A5.607 5.607 0 011.4 7c0-3.087 2.513-5.6 5.6-5.6 3.087 0 5.6 2.513 5.6 5.6 0 3.087-2.513 5.6-5.6 5.6zM7 0a7 7 0 100 14A7 7 0 007 0zm-.7 10.5h1.4V6.3H6.3v4.2z",
                          fill: "#DD181F",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "price-login-alert__text" }, [
                  _vm._v("Za prikaz cijene, potrebna je prijava."),
                ]),
              ]),
            ]
          ),
    ]),
    _vm._v(" "),
    _c("a", {
      staticClass: "mc-product__link",
      attrs: {
        href:
          "/proizvodi/" +
          _vm.product.category.root.slug +
          "/" +
          _vm.product.category.slug +
          "/" +
          _vm.product.slug,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }