var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "homepage" },
    _vm._l(_vm.configuration, function (section, key) {
      return _c(
        "section",
        { key: key, class: ["section", "section--" + section.slug] },
        [
          section.slug === "main-slider"
            ? _c("MainSlider", {
                attrs: {
                  title: section.payload.title,
                  subtitle: section.payload.subtitle,
                  entities: section.payload.entities,
                  "user-username": _vm.userUsername,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          section.slug === "products-slider"
            ? _c("ProductsSlider", {
                attrs: {
                  title: section.payload.title,
                  subtitle: section.payload.subtitle,
                  entities: section.payload.entities,
                  "user-username": _vm.userUsername,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          section.slug === "latest-news"
            ? _c("LatestNews", {
                attrs: {
                  title: section.payload.title,
                  subtitle: section.payload.subtitle,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          section.slug === "quick-search"
            ? _c("QuickSearch", {
                attrs: { "user-username": _vm.userUsername },
              })
            : _vm._e(),
          _vm._v(" "),
          section.slug === "product-categories"
            ? _c("ProductCategories")
            : _vm._e(),
          _vm._v(" "),
          section.slug === "product-banner" ? _c("ProductBanner") : _vm._e(),
          _vm._v(" "),
          section.slug === "brands"
            ? _c("Brands", {
                attrs: {
                  title: section.payload.title,
                  subtitle: section.payload.subtitle,
                  entities: section.payload.entities,
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }