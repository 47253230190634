<template>
  <div class="products-filter">
    <div class="products-filter__header">
      <span class="products-filter__icon">
        <svg width="40" height="34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M36 4H0V0h36v4zM21.62 20H14v4h6.18c.24-1.44.74-2.78 1.44-4zM30 10H6v4h24v-4zm0 8v6h-6v4h6v6h4v-6h6v-4h-6v-6h-4z" fill="#BFBFBF"/></svg>
      </span>
      <div class="products-filter__title">Filter proizvoda</div>
      <div @click="toggle" class="products-filter__arrow">
        <svg width="24" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.82 0L12 9.279 21.18 0 24 2.87 12 15 0 2.87 2.82 0z" fill="#000"/></svg>
      </div>
    </div>
    <div v-if="isOpen" class="products-filter__body">
      <div class="products-filter__search">
        <input type="text" class="products-filter__search-input" placeholder="Pretraži proizvode...">
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/api.service"

export default {
  name: 'ProductsFilter',
  data() {
    return {
      categories: [],
      filteredCategories: [],
      isLoaded: false,
      isOpen: false
    }
  },
  mounted() {
    this.getProductsCategories('/api/v1/product_categories').then(({ data }) => {
      this.categories = [...data['hydra:member']]
      this.isLoaded = true

      this.categories.forEach(category => {
        if (category['@id'] === category.root) {
          this.filteredCategories.push({
            title: category.title,
            slug: category.slug,
            children: []
          })
        }
      })

      this.categories.forEach(category => {
        if (category['@id'] !== category.root) {
          this.filteredCategories.forEach((filteredCategory, key) => {
            if (category.parent.slug === filteredCategory.slug) {
              this.filteredCategories[key].children.push(category)
            }
          })
        }
      })
    })
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
    async getProductsCategories(endpoint, filter) {
      try {
        return await ApiService.get(endpoint, filter)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>
