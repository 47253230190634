<template>
  <a class="user-menu__link" href="/kosarica">
    <span class="user-menu__link-text">Košarica</span>
    <span class="user-menu__link-icon">
      <svg width="20" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 15.943c0-.88.746-1.595 1.667-1.595.92 0 1.666.714 1.666 1.595 0 .88-.746 1.594-1.666 1.594-.92 0-1.667-.714-1.667-1.594zM15.833 15.943c0-.88.56-1.595 1.25-1.595s1.25.714 1.25 1.595c0 .88-.56 1.594-1.25 1.594s-1.25-.714-1.25-1.594z" fill="none"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0 .755C0 .338.328 0 .733 0h2.934a.74.74 0 01.722.624l2.093 12.214H17.6c.405 0 .733.338.733.755a.744.744 0 01-.733.756H5.867a.74.74 0 01-.723-.624L3.051 1.51H.733A.745.745 0 010 .755z" fill="none"/><path fill-rule="evenodd" clip-rule="evenodd" d="M18.858 2.391c.169 0 .335.036.488.106.153.069.288.17.395.295.107.126.184.273.225.43.041.157.045.321.012.48l-1.37 6.577a1.09 1.09 0 01-.397.633c-.204.16-.46.248-.724.248v-.73.73H5.617c-.42 0-.76-.327-.76-.73 0-.404.34-.731.76-.731h11.559l-.062.293.374.072.373.072-.373-.072-.374-.072a.363.363 0 01.132-.21.39.39 0 01.241-.083h-.311l1.218-5.846h.464a.394.394 0 01-.294-.134.358.358 0 01-.08-.303l-.09.437h-14.3c-.42 0-.76-.327-.76-.73 0-.404.34-.732.76-.732h14.764v.731-.73z" fill="none"/></svg>
      <span class="user-menu__badge">5</span>
    </span>
  </a>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: 'QuickCart',
  computed: {
    ...mapGetters(['cartQuantity'])
  },
  data() {
    return {}
  },
  created() {
    //this.$store.dispatch("getCartItems");
  },
  methods: {}
}
</script>
